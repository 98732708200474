html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.Stocks {
  width: 100%;
  height: 100vh;
}

.makeStyles-root-1 {
  width: 60% !important;
}

.navigate #stocksbuttom {
  font-weight: bold;
}
.Stock {
  height: 8em;
  position: relative;
}
.Stock h1 {
  position: absolute;
  top: 60%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 2.5em;
}

.navigate {
  position: absolute;
  top: 2%;
  left: 2%;
}

.MuiPaper-root {
  width: 70%;
}

.btn1 {
  width: 150px;
  background-color: white;
  color: rgb(5, 10, 90);
  border: 2px solid #008cba;
  display: inline-block;
  padding: 13px 30px;
  font-size: 1.4em;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 40px;
}

.progress {
  display: flex;
  justify-content: center;
  margin-top: 20%;
  flex-direction: column;
  align-items: center;
}

.loadingMessage {
  /* AE fixes
  margin-top: 2em;*/
  font-family: VWTEXT;
  font-size: 16px;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.app {
  background-image: url("/images/carro-homepage.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.signUp {
  display: flex;
  flex-direction: row-reverse;
  padding: 18px;
}

.signUpBtn {
  padding: 2px 10px 2px 6px;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border-color: transparent;
  font-family: VWTEXT;
  cursor: pointer;
  transform: scale(0.9);
}

.footerBar {
  position: fixed;
  text-align: end;
  bottom: 0px;
  height: 50px;
  width: 100%;
}

.footerLinksBlack {
  font-size: 0.8em;
  font-weight: bold;
  font-family: VWTEXT;
  text-decoration: solid;
  padding: 0 0.5em;
  margin-right: 24px;
  color: rgb(23, 18, 102);
}

.footerLinksWhite {
  font-size: 0.8em;
  font-weight: bold;
  font-family: VWTEXT;
  text-decoration: solid;
  padding: 0 0.5em;
  margin-right: 24px;
  color: white;
}

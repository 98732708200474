html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.Invoices {
  width: 100%;
  height: 100vh;
}
.makeStyles-root-1 {
  width: 50% !important;
}
.navigate #invbuttom {
  font-weight: bold;
}

.Invoice {
  height: 8em;
  position: relative;
}
.Invoice h1 {
  position: absolute;
  top: 60%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 2.5em;
}

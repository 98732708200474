html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.root {
  background-image: url("/images/carro-homepage.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.btn {
  width: 150px;
  color: white;
  background-color: rgb(0, 32, 77);
  padding: 15px 30px;
  font-size: 0.8em;
  cursor: pointer;
  border: none;
  border-radius: 40px;
  font-family: VWTEXT;
  margin-right: 8px;
}

.btn:hover {
  background-color: rgb(20, 40, 90);
}

.logout {
  display: flex;
  flex-direction: row-reverse;
  padding: 18px;
}

.logoutBtn {
  padding: 2px 10px 2px 6px;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border-color: transparent;
  font-family: VWTEXT;
  cursor: pointer;
  transform: scale(0.9);
}

.logoutImg {
  color: white;
  padding-left: 4px;
  display: none; /* AE fixes */
}

.linhaLogo {
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 36px;
  /*
  AE fixes
  top: 30px;
  */
}

.linha,
.linha2 {
  height: 2px;
  background-color: #fff;
  width: 100%;
  flex-grow: 1;
}

.linha {
  flex-basis: 225% !important;
}

.imgLogo {
  margin: 0px 12px;
  flex-shrink: 0;
  /*
  AE fixes
  width: 48px;
  height: 48px;
  */
  width: 52px;
  height: 52px;
}

.supplynet {
  position: absolute;
  top: 14em;
  left: 8.5%;
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  font-family: VWHEAD;
}

.welcome {
  position: absolute;
  top: 3.5em;
  left: 8.5%;
  font-size: 6em;
  font-weight: bold;
  color: white;
  font-family: VWHEAD;
}

.buttoms {
  position: absolute;
  bottom: 38%;
  left: 8.5%;
}

.name {
  color: #fff;
  font-weight: 300;
  font-family: VWTEXT;
  margin-right: 8px;
  font-size: smaller;
  display: none; /* AE fixes */
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.Infos {
  width: 100%;
  height: 100vh;
}
.makeStyles-root-1 {
  width: 70%;
}

.MuiPopover-paper {
  width: 2% !important;
}
.Info {
  height: 8em;
  position: relative;
}
.Info h1 {
  position: absolute;
  top: 60%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 2.5em;
}

.navigate #backbuttom {
  font-weight: bold;
}

.logoutBlackBtn {
  padding: 2px 10px 2px 6px;
  font-weight: bold;
  color: black;
  background-color: transparent;
  border-color: transparent;
  font-family: VWTEXT;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logoutBlackImg {
  color: black;
  padding-left: 4px;
  display: none; /*AE fixes*/
}

.nameBlack {
  color: black;
  font-weight: 300;
  font-family: VWTEXT;
  margin-right: 8px;
  font-size: smaller;
  display: none; /*AE fixes*/
}

table {
  font-family: VWTEXT;
  border: 2.5px solid black;
  border-width: 2.5px 0;
  border-left: 2.5px solid white;
  border-right: 2.5px solid white;
  margin-top: 4em;
  font-size: 16px;
  border-collapse: collapse;
}

td,
th {
  padding: 8px 16px;
  border: 1px solid black;
  border-collapse: collapse;
}

.editIcon {
  color: rgb(0, 32, 77);
  cursor: pointer;
}

.editInput {
  font-family: VWTEXT;
  font-size: 1em;
  width: 100%;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
}

.editInput:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.dateInput {
  padding: 8px 8px 8px 8px;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  font-size: 1.13em;
  width: 210px;
  font-family: VWTEXT;
}

.dateInput:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.noRecords {
  padding: 1em 1em;
}

.footer {
  padding: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 90%;
}

.dot {
  height: 4px;
  width: 4px;
  background-color: rgb(23, 18, 102);
  border-radius: 50%;
  position: relative;
  margin: 4px;
  cursor: pointer;
}

.selectedDot {
  height: 8px;
  width: 8px;
  background-color: rgb(23, 18, 102);
  border-radius: 50%;
  position: relative;
  margin: 4px;
}

.spaceDot {
  height: 4px;
  width: 24px;
  background-color: rgb(23, 18, 102);
  margin: 4px;
  opacity: 25%;
}

.dot .tooltip {
  visibility: hidden;
  background-color: #c2cacf;
  color: #fff;
  text-align: center;
  padding: 8px;
  top: 350%;
  margin-left: -12px;
  border-radius: 8px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-family: VWTEXT;
}

.selectedDot .tooltip {
  visibility: hidden;
  background-color: #c2cacf;
  color: #fff;
  text-align: center;
  padding: 8px;
  top: 200%;
  margin-left: -12px;
  border-radius: 8px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-family: VWTEXT;
}

.dot:hover .tooltip {
  visibility: visible;
}
.selectedDot:hover .tooltip {
  visibility: visible;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
  background-color: #c2cacf;
  border-radius: 12px;
  border: 1px solid #c2cacf;
}

.toggle:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 2px;
  transition: all 0.5s;
}

.checkbox:checked + .toggle::after {
  left: 20px;
}

.checkbox:checked + .toggle {
  background-color: rgb(23, 18, 102);
}

.checkbox {
  display: none;
}

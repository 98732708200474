.Login {
  width: 100%;
  height: 100%;
}

.Login form {
  width: auto;
  height: auto;
  padding: 13% 30%;
}

.Login input[type="text"],
select {
  width: 400px;
  padding: 12px 0px;
  margin: 10px 0px;
  margin-top: 1em;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

::placeholder {
  color: rgb(23, 18, 102);
  font-size: 0.75em;
  font-family: VWTEXT;
  /*AE fixes*/
}

.Login input[type="password"],
select {
  width: 400px;
  padding: 12px 0px;
  margin: 10px 0px;
  margin-top: 1em;
  display: inline-block;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.Login input[type="text"]:focus,
input[type="password"]:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.linhaLogo2 {
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 36px;
  /*
  AE fixes
  top: 30px;
  */
}

.linha3,
.linha4 {
  height: 2px;
  background-color: #00204d;
  width: 100%;
  flex-grow: 1;
}

.linha3 {
  flex-basis: 225% !important;
}

.inputs {
  font-family: VWTEXT;
}

.info3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: VWTEXT;
}

.signBtn {
  width: 150px;
  color: #3c484d;
  background-color: #c2cacf;
  padding: 15px 30px;
  font-size: 0.8em;
  cursor: pointer;
  border: none;
  border-radius: 40px;
  font-family: VWTEXT;
}

.signBtn:hover {
  background-color: #7c848c;
}

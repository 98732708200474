.Register {
  width: 100%;
  height: 100%;
}

.Register form {
  width: auto;
  height: auto;
  padding: 7.5% 20%;
}

.makeStyles-formControl-2 {
  margin: 5px !important;
  min-width: 120px;
}

.MuiFormLabel-root {
  font-size: 1.2em !important;
}

.Register input#VAT {
  margin-right: 0px !important;
  width: 70%;
  margin-left: 1em;
  font-family: VWTEXT;
}

.Register select {
  padding: 12px 0px;
  margin: 10px 0px;
  margin-top: 1em;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
  background: white;
  width: fit-content;
}

.Register select:invalid {
  color: rgb(23, 18, 102);
  font-size: 0.9em;
  font-family: VWTEXT;
}

.Register select option {
  font-size: 1em;
  color: black;
  font-family: VWTEXT;
}

.Register input[type="text"],
select {
  width: -webkit-fill-available;
  padding: 12px 0px;
  margin: 10px 0px;
  margin-top: 1em;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

::placeholder {
  color: rgb(23, 18, 102);
  font-size: 0.75em;
  font-family: VWTEXT;
  /*AE fixes*/
}

.Register input[type="password"],
select {
  width: -webkit-fill-available;
  padding: 12px 0px;
  margin: 10px 0px;
  margin-top: 1em;
  display: inline-block;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.Register input[type="text"]:focus,
input[type="password"]:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.selectField {
  background: white;
}

.message {
  margin: 2em 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messageContent {
  padding-bottom: 20px;
}

.successMessage {
  color: green;
}

.errorMessage {
  color: red;
}

.transparentMessage {
  color: transparent;
}

.vatField {
  display: flex;
  align-items: flex-end;
}

.countryField {
  width: 80px;
  margin-right: 0;
}

.italic {
  font-style: italic;
}

.disclaimer {
  font-size: 0.75em;
  font-family: VWTEXT;
  margin: 1em 5em;
}

.goToSignIn {
  margin-top: 1.5em;
  font-size: 0.75em;
  font-family: VWTEXT;
}

.signInLink {
  text-decoration: none;
}

input:focus {
  outline: 0;
}

.title {
  display: flex;
  margin-bottom: 56px;
  justify-content: center;
}

.title1 {
  font-size: 5em;
  font-family: VWHEAD;
}

.title2 {
  font-size: 5em;
  font-family: VWHEAD;
  font-weight: bold;
  margin-left: 30px;
}

.info2,
.info1 {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;
  */
  font-family: VWTEXT;
}

.registerDivs {
  flex: 1;
}

.registerDivs:first-child {
  margin-right: 20px;
}

.registerDivs:last-child {
  margin-left: 20px;
}

.inputs {
  font-family: VWTEXT;
}

.xrow {
  margin: 0% 5% 0% 5%;
  display: flex;
  justify-content: space-between;
}

.xcell {
  width: 45%;
  display: inline-block;
}

.PrivacyPolicy {
  width: 100%;
  height: 100%;
}

.privacyPolicyContent {
  padding: 2em 15em;
}

.privacyPolicyTitle {
  display: flex;
  margin: 2em;
  justify-content: center;
  font-size: 4em;
  font-family: VWHEAD;
  text-align: center;
}

.privacyPolicySubtitle {
  display: flex;
  margin: 1em 2.5em;
  font-size: 3em;
  font-family: VWHEAD;
  font-weight: bold;
}

.privacyPolicy2Subtitle {
  margin: 1em 2.5em;
  font-size: 3em;
  font-family: VWHEAD;
  font-weight: bold;
}

.privacyPolicyTextTitle {
  display: flex;
  padding: 1em 8em 0.5em 8em;
  font-size: 1.5em;
  font-family: VWTEXT;
  font-weight: bold;
}

.privacyPolicyText {
  padding: 0 10em;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.privacyPolicyEmail {
  font-size: 1em;
  font-family: VWTEXT;
  color: rgb(23, 18, 102);
  font-weight: bold;
}

.privacyPolicyDate {
  display: flex;
  padding: 2em 10em;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.privacyPolicyTextBullets {
  margin-left: 11em;
  padding: 0 10em 0 0;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.title {
  display: flex;
  margin-bottom: 56px;
  justify-content: center;
}

.title1 {
  font-size: 5em;
  font-family: VWHEAD;
}

.title2 {
  font-size: 5em;
  font-family: VWHEAD;
  font-weight: bold;
  margin-left: 30px;
}

.info2,
.info1 {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;
  */
  font-family: VWTEXT;
}

.registerDivs {
  flex: 1;
}

.registerDivs:first-child {
  margin-right: 20px;
}

.registerDivs:last-child {
  margin-left: 20px;
}

.inputs {
  font-family: VWTEXT;
}

.xrow {
  margin: 0% 5% 0% 5%;
  display: flex;
  justify-content: space-between;
}

.xcell {
  width: 45%;
  display: inline-block;
}

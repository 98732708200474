body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, VWHEAD, VWTEXT;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, VWHEAD, VWTEXT;
}

@font-face {
  font-family: VWHEAD;
  src: url("fonts/VWHEAD/VWHEAD-REGULAR.OTF");
}
@font-face {
  font-family: VWHEAD;
  font-weight: italic;
  src: url("fonts/VWHEAD/VWHEAD-REGULARITALIC.OTF");
}
@font-face {
  font-family: VWHEAD;
  font-weight: bold;
  src: url("fonts/VWHEAD/VWHEAD-BOLD.OTF");
}
@font-face {
  font-family: VWHEAD;
  font-weight: bold;
  font-style: italic;
  src: url("fonts/VWHEAD/VWHEAD-BOLDITALIC.OTF");
}
@font-face {
  font-family: VWHEAD;
  font-weight: bolder;
  src: url("fonts/VWHEAD/VWHEAD-EXTRABOLD.OTF");
}
@font-face {
  font-family: VWHEAD;
  font-weight: lighter;
  src: url("fonts/VWHEAD/VWHEAD-LIGHT.OTF");
}

@font-face {
  font-family: VWTEXT;
  src: url("fonts/VWTEXT/VWTEXT-REGULAR.OTF");
}
@font-face {
  font-family: VWTEXT;
  font-style: italic;
  src: url("fonts/VWTEXT/VWTEXT-REGULARITALIC.OTF");
}
@font-face {
  font-family: VWTEXT;
  font-weight: bold;
  src: url("fonts/VWTEXT/VWTEXT-BOLD.OTF");
}
@font-face {
  font-family: VWTEXT;
  font-weight: bold;
  font-style: italic;
  src: url("fonts/VWTEXT/VWTEXT-BOLDITALIC.OTF");
}
@font-face {
  font-family: VWTEXT;
  font-weight: lighter;
  src: url("fonts/VWTEXT/VWTEXT-LIGHT.OTF");
}

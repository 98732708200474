html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.Profiles {
  width: 100%;
  height: 100vh;
}

.Profile {
  height: 8em;
  position: relative;
}

.Profile form {
  width: auto;
  height: auto;
  padding: 8% 20%;
}

.Profile input[type="text"],
select {
  width: 100%;
  padding: 5px 0px;
  margin: 10px 0px;
  margin-right: 7.5%;
  margin-top: 2em;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.Profile input:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.Profile input:read-only {
  width: 100%;
  padding: 5px 0px;
  margin: 10px 0px;
  margin-right: 7.5%;
  margin-top: 2em;
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
}

.Profile label {
  position: absolute;
  display: block;
  width: auto;
  color: #00204d;
  font-weight: bold;
  font-family: VWTEXT;
}

.Info3 {
  display: inline-block;
  width: 45%;
  height: 5em;
  margin-right: 2em;
  padding-bottom: 1em;
}

.tabPadding {
  display: flex;
  margin: 6.5% 0% 0% 5%;
  padding-top: 4px;
}

.disabledBtn {
  width: 180px;
  background-color: #c2cacf;
  padding: 15px 30px;
  font-size: 0.8em;
  border: none;
  border-radius: 40px;
  font-family: VWTEXT;
  margin-right: 8px;
}

.btn2 {
  width: 180px;
  color: white;
  background-color: rgb(0, 32, 77);
  padding: 15px 30px;
  font-size: 0.8em;
  cursor: pointer;
  border: none;
  border-radius: 40px;
  font-family: VWTEXT;
  margin-right: 8px;
}

.passwords {
  width: -webkit-fill-available;
  padding: 12px 0px 6px 0px;
  margin: 10px 0px;
  margin-top: 1em;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.passwords:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.tabRow {
  width: 250px;
  display: flex;
  border: 2px solid #c2cacf;
  border-width: 2px 0 0;
  padding-top: 0.8em;
  justify-content: space-between;
  font-family: VWTEXT;
  font-size: 0.8em;
  color: rgb(23, 18, 102);
}

.tab {
  cursor: pointer;
}

.selectedTab {
  border: 3px solid rgb(23, 18, 102);
  border-width: 3px 0 0;
  padding-top: 0.8em;
  margin-top: -0.95em;
  cursor: pointer;
}

.tabSearch {
  margin: 3% 5% 3% 5%;
  display: flex;
  justify-content: space-between;
}

.searchBackofficeColumn {
  transform: scale(0.9);
}

.searchColumn {
  transform: scale(0.9);
  display: flex;
  align-items: center;
}

.searchItem {
  margin-right: 3.5em;
}

.searchName {
  display: flex;
  font-family: VWTEXT;
  font-size: 0.9em;
  color: rgb(23, 18, 102);
}

.searchRow {
  display: flex;
  align-items: center;
}

.searchBackofficeIcon {
  color: rgb(23, 18, 102);
  cursor: pointer;
  position: absolute;
}

.clearSearchBackofficeIcon {
  color: #c2cacf;
  cursor: pointer;
  position: absolute;
  margin-left: 9.5em;
}

.searchBackofficeBox {
  padding: 8px 16px 8px 24px;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.searchBackofficeBox:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}

.searchIcon {
  color: rgb(23, 18, 102);
  cursor: pointer;
  margin-left: 3em;
  margin-top: 0.7em
}

.exportIcon {
  color: rgb(23, 18, 102);
  cursor: pointer;
  margin-left: 0.75em;
  margin-top: 0.7em
}

.clearSearchIcon {
  color: #c2cacf;
  cursor: pointer;
  position: absolute;
  margin-left: 9.5em;
}

.searchBox {
  padding: 8px 8px 8px 8px;
  border: 2px solid #c2cacf;
  border-width: 0 0 1px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2em;
  font-family: VWTEXT;
}

.searchBox:focus {
  border: 2px solid rgb(23, 18, 102);
  border-width: 0 0 3px;
}
